import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, Skeleton } from '@mui/material';
/**
 * 
 * @param props 
 * @returns 
 */
export default function HeaderCard(props: any) {
    const loader = () => {
        return (
            <CardContent>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
            </CardContent>
        )
    }
    const content = () => {
        return (
            <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                    {/* <AnalyticsIcon fontSize='large' /> */}
                    {props.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {props.description}
                </Typography>
            </CardContent>
        );
    }
    return (
        <Card sx={{}}>
            <CardActionArea>
                <CardContent>
                    {(props.title == null) ? loader() : content()}

                </CardContent>
            </CardActionArea>
        </Card>
    );
}