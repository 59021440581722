import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';
import Dashboard from './components/dashboard/Dashboard';
import Passwordreset from './components/auth/PasswordReset';
import { roleGuard } from './guards/AuthGuard';
import CreateRelease from './components/releases/createRelease';
import { ListProducts } from './components/products/list-products/ListProducts';
import CreateProduct from './components/products/create-product/CreateProduct';
import CreateUserGroup from './components/user-groups/create-user-group/CreateUserGroup';
import AddUser from './components/user-groups/add-user/AddUser';
import { ListActions } from './components/myactions/list-actions/ListActions';
import { ProductDashboard } from './components/products/dashboard/ProductDashboard';
import { ListReleases } from './components/releases/list-releases/ListReleases';
import { ReleaseManifest } from './components/storefront/release-manifest/ReleaseManifest';
import CreateReleaseTopics from './components/releases-topics/createReleaseTopics';
import { ListReleaseTopics } from './components/releases-topics/list-release-topics/ListReleaseTopics';
import { Details } from './components/releases-topics/details/Details';
import ListNotifications from './components/notifications/list-notifications/ListNotifications';
import { CommitRelease } from './components/releases/commit-release/CommitRelease';
import { ReleaseVoting } from './components/releases/release-voting/ReleaseVoting';
import UpdateRelease from './components/releases/updateRelease/UpdateRelease';
import ManageRelease from './components/releases/manage-release/ManageRelease';
import Profile from './components/profile/Profile';
import Account from './components/account/Account';
import TopicGrooming from './components/releases-topics/topic-grooming/TopicGrooming';
import UpdateReleaseTopic from './components/releases-topics/update';
import UpdateProduct from './components/products/update';
import { ListRoadMap } from './components/roadmaps/listRoadMap/ListRoadMap';
import CreateRoadMap from './components/roadmaps/createRoadMap/CreateRoadMap';
import UpdateRoadMap from './components/roadmaps/update';
import { ListRoadMapMilestoneView } from './components/roadmaps/listRoadMap/ListRoadMapMilestoneView';
import ListUserGroups from './components/user-groups/list-user-group/ListUserGroups';
import ListUsersByGroup from './components/user-groups/list-users/ListUsersByGroup';
import StickLayout from './layout/StickLayout';
import { ListValidationPlan } from './components/validation-plans/listValidationPlans/ListValidationPlan';
import CreateValidationPlan from './components/validation-plans/createValidationPlan/CreateValidationPlan';
import { ListValidationPlansWithTopics } from './components/validation-plans/listValidationPlansWithTopics/ListValidationPlansWithTopics';
import CreateProject from './components/projects/createProject/CreateProject';
import { ListProjects } from './components/projects/list-projects/ListProjects';
import UpdateValdiationPlans from './components/validation-plans/update';
import Users from './components/users/list-users/Users';
import AddNewUser from './components/users/add-user/AddNewUser';
import { ListMilestones } from './components/milestones/list/ListMilestones';
import CreateMilestones from './components/milestones/create/CreateMilestones';
import ChangePassword from './components/profile/ChangePassword';
import ChangeUserPassword from './components/users/ChangePassword/ChangeUserPassword';
import ProjectSelector from './components/selector/Selector';
import Selector from './components/selector/Selector';
import CreateMetrics from './components/metrics/create-metrics/CreateMetrics';
import { ListMetrics } from './components/metrics/list-metrics/ListMetrics';
import { ListMetricInsights } from './components/metrics/list-metric-insights/ListMetricInsights';
import CreateMetricInsights from './components/metrics/create-metric-insights/CreateMetricInsights';

export default function AppRoutes(props: any): React.ReactElement {
  const routes = createBrowserRouter([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          Component: Login,
        },
        {
          path: 'signup',
          Component: Signup,
        },
        {
          path: 'password-reset',
          Component: Passwordreset,
        },
        {
          path: '',
          element: <Navigate to="/auth/login" />,
        },
      ],
    },
    {
      path: 'select',
      element: <Selector />,
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId',
      Component: StickLayout,
      loader: roleGuard.protectedLoader,
      children: [
        {
          path: 'dashboard',
          Component: Dashboard,
        },
        {
          path: 'products',
          Component: ListProducts,
        },
      ],
    },
    {
      path: 'projects',
      children: [
        {
          path: 'new',
          Component: CreateProject,
        },
        {
          path: '',
          Component: ListProjects,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId/user-groups',
      Component: StickLayout,
      children: [
        {
          path: 'new',
          Component: CreateUserGroup,
        },
        {
          path: ':usergroupid/add-user',
          Component: AddUser,
        },
        {
          path: ':usergroupid/users',
          Component: ListUsersByGroup,
        },
        {
          path: '',
          Component: ListUserGroups,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId/validation-plans',
      Component: StickLayout,
      children: [
        {
          path: '',
          Component: ListValidationPlan,
        },
        {
          path: ':validationPlanId/edit',
          Component: UpdateValdiationPlans,
        },
        {
          path: 'new',
          Component: CreateValidationPlan,
        },
        {
          path: ':validationPlanId/release-topics',
          Component: ListValidationPlansWithTopics,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId/products',
      Component: StickLayout,
      loader: roleGuard.protectedLoader,
      children: [
        {
          path: '',
          Component: ListProducts,
        },
        {
          path: 'create',
          Component: CreateProduct,
        },
        {
          path: ':productId',
          Component: ProductDashboard,
        },
        {
          path: ':productId/releases',
          Component: ListReleases,
        },
        {
          path: ':productId/release/create',
          Component: CreateRelease,
        },
        {
          path: ':productId/edit',
          Component: UpdateProduct,
        },
        {
          path: ':productId/release/:releaseId',
          Component: CreateRelease,
        },
        {
          path: ':productId/release/:releaseId/update',
          Component: UpdateRelease,
        },
        {
          path: ':productId/release/:releaseId/manage',
          Component: ManageRelease,
        },
        {
          path: ':productId/release/:releaseId/commit',
          Component: CommitRelease,
        },
        {
          path: ':productId/release/:releaseId/vote/:actionId',
          Component: ReleaseVoting,
        },
      ],
    },
    {
      path: ':projectId/release-topics',
      Component: StickLayout,
      children: [
        {
          path: '',
          Component: ListReleaseTopics,
        },
        {
          path: 'create',
          Component: CreateReleaseTopics,
        },
        {
          path: 'list',
          Component: ListReleaseTopics,
        },
        {
          path: ':topicId/details',
          Component: Details,
        },
        {
          path: ':topicId/groom',
          Component: TopicGrooming,
        },
        {
          path: ':topicId/edit',
          Component: UpdateReleaseTopic,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId/myactions',
      Component: StickLayout,
      children: [
        {
          path: '',
          Component: ListActions,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId/roadmaps',
      Component: StickLayout,
      children: [
        {
          path: '',
          Component: ListRoadMap,
        },
        {
          path: 'timelineview',
          Component: ListRoadMapMilestoneView,
        },
        {
          path: 'create',
          Component: CreateRoadMap,
        },
        {
          path: ':roadmapId',
          Component: UpdateRoadMap,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId/milestones',
      Component: StickLayout,
      children: [
        {
          path: '',
          Component: ListMilestones,
        },
        {
          path: 'new',
          Component: CreateMilestones,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId/release-manifest',
      Component: StickLayout,
      children: [
        {
          path: ':productId/releases/:releaseId',
          Component: ReleaseManifest,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId/notifications',
      Component: StickLayout,
      children: [
        {
          path: '',
          Component: ListNotifications,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId',
      Component: StickLayout,
      children: [
        {
          path: 'profile',
          Component: Profile,
        },
        {
          path: 'password-reset',
          Component: ChangePassword,
        },
        {
          path: 'users/:userId/password-reset',
          Component: ChangeUserPassword,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId/account',
      Component: StickLayout,
      children: [
        {
          path: '',
          Component: Account,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId/users',
      Component: StickLayout,
      children: [
        {
          path: '',
          Component: Users,
        },
        {
          path: 'new',
          Component: AddNewUser,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: ':projectId/metrics',
      Component: StickLayout,
      children: [
        {
          path: '',
          Component: ListMetrics,
        },
        {
          path: 'new',
          Component: CreateMetrics,
        },
        {
          path: ':metricId/insights',
          Component: ListMetricInsights,
        },
        {
          path: ':metricId/insights/new',
          Component: CreateMetricInsights,
        },
      ],
      loader: roleGuard.protectedLoader,
    },
    {
      path: '/login',
      Component: Login,
    },
    {
      path: '*',
      Component: Login,
    },
  ]);

  return <RouterProvider router={routes} />;
}
