import { Button } from '@mui/material';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
interface Props {
  text: string;
}
/**
 * https://www.assemblyai.com/blog/react-text-to-speech-simplified/
 */
function TTSSpeaker(props: Props): React.ReactElement {
  const lib = new SpeechSynthesisUtterance();
  const speak = (msg: string) => {
    lib.text = msg;
    window.speechSynthesis.speak(lib);
  };
  return (
    <div className="App">
      <Button
        variant="contained"
        onClick={(evt) => {
          speak(props.text);
        }}
        sx={{ margin: 1 }}
      >
        <RecordVoiceOverIcon />
      </Button>
    </div>
  );
}

export default TTSSpeaker;
