import { styled } from '@mui/material';
import { COLOR_FADE_BLACK_PRIMARY, COLOR_FADE_BLACK_SECONDARY } from '../../../../styles/values/Colors';
import { SIZE_HIGH_1, SIZE_MUDIUM_2 } from '../../../../styles/values/FontSize';
import { DEFAULT_FONT_FAMILY } from '../../../../styles/values/FontFamily';

export const HeaderGrid = styled('div')({
  width: '100%',
});
export const GridItemName = styled('div')({
  color: 'darkslategray',
  display: 'inline',
  width: '80%',
  fontSize:'25px'
});
export const GridSecondaryItemName = styled('p')({
  color: '#627065',
  display: 'inline',
  width: '80%',
  fontSize:'15px',
  padding:'5px',
  marginTop:'5px'
});
export const GridItemStatus = styled('div')({
  color: 'darkslategray',
  display: 'inline',
  width: '20%',
  marginRight:'10px'
});
export const HeaderLabel = styled('div')({
  fontSize: 20,
  width: '80%',
  border: '2px solid #000',
});


export const ReleaseCommitmentGrid = styled('div')({
  minWidth:'600px',
  padding:'5px',
  background:'#2c4389',
  border:'2px solid #2c4389',
  borderRadius:'5px'
});
/**
 * Release Commitment Name
 */
export const ReleaseCommitmentLabel = styled('div')({
  fontSize: 20,
  display:'inline',
  color:'#fff',
  width:'70%',
  paddingRight:10
});

/**
 * Release Version 
 */
export const ReleaseCommitmentSecondaryLabel = styled('div')({
  fontSize: 20,
  color:'#fff',
  display:'inline',
  width:'20%',  
  background:'#2c4389',
  borderLeft:'2px solid #fff',
  paddingLeft:20,
});

/**
 * Styles
 */
export const DetailsHeadingText = {

  color: COLOR_FADE_BLACK_PRIMARY,
  fontSize: SIZE_HIGH_1,
  fontWeight: 'bold',
  fontFamily: DEFAULT_FONT_FAMILY,
}
export const DetailsInnerText = {
  
  color: COLOR_FADE_BLACK_SECONDARY, 
  fontSize: SIZE_MUDIUM_2, 
  fontFamily: DEFAULT_FONT_FAMILY 
}