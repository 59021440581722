import { Box, Button, Chip, Container, Divider, Paper, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import React from 'react';
import { ReleaseTopics } from '../../../utils/interface/ReleaseTopicInterface';
import { getReleaseTopicById, patchReleaseTopic } from '../../../services/ReleaseTopics';
import { BackdropComponent, BackdropSkeletonComponent } from '../../shared-components/backdrop/Backdrop';
import sanitizeHtml from 'sanitize-html';
import { ActionButtonGrid, ReleaseTopicHeader, WriterGrid } from './styles/TopicGroomingStyles';

import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { HeaderDetails } from '../details/HeaderDetails';
import Dictaphone from '../../shared-components/text-speech/TextToSpeech';
import TTSSpeaker from '../../shared-components/text-speech/TTSSpeaker';
interface ChipData {
  key: number;
  label: string;
}

export default function TopicGrooming() {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [releaseTopic, setReleaseTopic] = useState<ReleaseTopics>();
  const { projectId = '', topicId = '' } = useParams();
  const [changeDetected, setChangeDetected] = React.useState<boolean>(false);

  //const onContentBlur = React.useCallback(evt => setContent(evt.currentTarget.innerHTML))
  const getTopicByIdAPI = async () => {
    if (topicId) {
      setOpen(true);
      const resultTopicDetails = await getReleaseTopicById(projectId, topicId);
      setReleaseTopic(resultTopicDetails.data);
      if (resultTopicDetails.data.content) {
        setValue(resultTopicDetails.data.content);
      }

      setOpen(false);
    }
  };
  useEffect(() => {
    getTopicByIdAPI();
  }, []);

  const patchReleaseTopicDoc = async () => {
    console.log('patchReleaseTopicDoc ', topicId);
    if (topicId != null) {
      setOpen(true);
      const payload = { content: value };
      await patchReleaseTopic(projectId, topicId, payload);
      setOpen(false);
    }
  };
  const onContentChange = React.useCallback((evt: any) => {
    setChangeDetected(true);
    const sanitizeConf = {
      allowedTags: ['b', 'i', 'a', 'p', 'br', 'div', 'hr', '&nbsp;'],
      allowedAttributes: { a: ['href'] },
    };

    setValue(sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf));
  }, []);

  const Editable = () => {
    return (
      <WriterGrid
        contentEditable
        onBlur={(evt) => {
          onContentChange(evt);
        }}
        // onBlur={true}
        dangerouslySetInnerHTML={{ __html: value }}
      ></WriterGrid>
    );
  };

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Paper elevation={2} sx={{ padding: 1 }}>
        {releaseTopic ? (
          <HeaderDetails releaseTopic={releaseTopic} open={open} />
        ) : (
          <BackdropSkeletonComponent visible={true} />
        )}
        
        <TTSSpeaker text={value} />
      </Paper>
      <Divider />
      <Box>
        {/* <Dictaphone  /> */}
        {Editable()}
        {changeDetected ? (
          <ActionButtonGrid>
            <Button variant="contained" onClick={patchReleaseTopicDoc} sx={{ margin: 1 }}>
              save
            </Button>
            <Button variant="outlined" onClick={(evt) => setValue('')} sx={{ margin: 1 }}>
              Clear
            </Button>
          </ActionButtonGrid>
        ) : (
          <></>
        )}

        {/* {constributors()} */}
      </Box>
    </Container>
  );
}
